import React, { useState } from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, Imgs, StoreItem } from './SidebarElements';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import gplaystore from '../../images/google-play-badge.png';
import applestore from '../../images/downloadonappstore.png';

const Sidebar = ({ isOpen, toggle, type }) => {
    const[scrollNav] = useState(false);

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu type={type}>
                    <SidebarLink to="about" onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="discover" onClick={toggle}>Discover</SidebarLink>
                    <SidebarLink to="services" onClick={toggle}>Services</SidebarLink>
                    <SidebarLink to="contact" onClick={toggle}>Contact</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap type={type}>
                <Popup trigger={<SidebarRoute to="" scrollNav={scrollNav}>Download Pacified® App</SidebarRoute>}modal nested>{close => (
                            <div className="modal">
                                <button className="close" onClick={close}>
                                &times;
                                </button>
                                <div class="content">
                                <StoreItem to={{pathname: "https://apps.apple.com/us/app/pacified/id1528971525"}} target="_blank">
                                    <Imgs src={applestore}></Imgs>
                                </StoreItem>
                                <StoreItem to={{pathname: "https://play.google.com/store/apps/details?id=info.toothprints.gnathic"}} target="_blank">
                                    <Imgs src={gplaystore}></Imgs>
                                </StoreItem>
                                </div>
                                {/* <div className="header"> Modal Title </div>
                                <div className="content">
                                {' '}
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
                                Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
                                delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
                                <br />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
                                commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
                                explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
                                </div> */}
                            </div>
                            )}
                        </Popup>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;
